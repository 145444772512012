html {
  height: 100%;
  scroll-behavior: smooth;
}

a {
  color: #2c3e50 !important;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.clickable:hover {
  cursor: pointer;
}

.noselect {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.hrLargeBlue {
  border-bottom: solid #2c3e50 6px;
  width: 350px;
  margin: 0 auto;
  margin-bottom: 10px;
  max-width: 100%;
}

.content-table-button {
  margin-top: 100px;
}

.error {
  text-align: center;
  color: red;
  margin-bottom: 10px;
}

.success {
  text-align: center;
  color: green;
  margin-bottom: 10px;
}

.blue {
  background-color: #2c3e50 !important;
}

.greenFont {
  color: green !important;
}

.redFont {
  color: red !important;
}

.center-div {
  margin: 0 auto;
}

.rounded {
  border-radius: 3%;
}

.additionalInfoBox {
  padding: 15px;
  border: 1px solid #dededf;
}

.streamScheduleCheckbox {
  font-weight: normal;
  margin-left: 10px;
  font-size: 8px;
}

.headerScroll {
  max-height: 60px !important;
  transition: max-height 0.15s ease-out;
}

.headerLogo {
  width: 50px !important;
  height: 50px !important;
}

a {
  font-weight: bold;
}

a:focus,
a:hover {
  color: #2c3e50;
  text-decoration: underline;
}

.main {
  padding-top: 50px;
  padding-bottom: 5%;
}

.center-div {
  margin: 0 auto;
  width: 575px;
}

.bg-secondary {
  background-color: #2c3e50 !important;
}

.fixed-top {
  top: 0;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.navbar,
.navbar > .container,
.navbar > .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .ui.menu {
    display: block !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;

    .item {
      padding: 10px !important;
      font-size: 16px;
    }

    .active.item {
      border-left: 2px solid #1b1c1d !important;
      border-bottom: 0 !important;
    }
  }

  .datePickerTo {
    display: block;
  }

  .streamScheduleTableRow {
    padding-bottom: 30px !important;
  }

  .ui.input {
    font-size: 16px !important;
  }

  .ui.search {
    font-size: 16px !important;
  }

  .ui.form textarea {
    font-size: 16px !important;
  }

  .MuiInputBase-root {
    font-size: 16px !important;
  }
}
